import { ConfigProvider } from "antd";
import es_ES from "antd/es/locale/es_ES";
import React from 'react';
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter, } from "react-router-dom";
import App from "./App";
import NotificationsHandler from "./components/Notifications";
import ConnectionHandler from "./connection/ConnectionHandler";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { store } from "./store";

import "./assets/fonts/index.less";
import "./styles/index.css";

const container = document.getElementById("root");
if (!container) throw new Error('No se encontró el elemento root');
const root = createRoot(container);

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<ConfigProvider locale={es_ES}>
					<App />
					<ConnectionHandler />
					<NotificationsHandler />
				</ConfigProvider>
			</BrowserRouter>
		</Provider>
	</React.StrictMode>
);

serviceWorkerRegistration.register();
