/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
export const THEME = "THEME";

/* <---- BASIC APP CONSTANTS ----> */

/* SOCKET CONSTANTS */
export const CONNECTED = "connected";
export const DISCONNECTED = "disconnected";
/* <---- SOCKET CONSTANTS ----> */

/* CONFIGURATION */
export const CONFIGURATION_FORM_TOOGLE_LOADING =
  "CONFIGURATION_FORM_TOOGLE_LOADING";
export const SET_CONFIGURATION = "SET_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USER_FORM = "OPEN_USER_FORM";
export const CLOSE_USER_FORM = "CLOSE_USER_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* ROUTES */
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
/* <---- ROUTES ----> */

/* ALERT */
export const SET_ALERT_LIST = "SET_ALERT_LIST";
export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERT_FORM = "OPEN_ALERT_FORM";
export const CLOSE_ALERT_FORM = "CLOSE_ALERT_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
export const ALERT_FORM_TOOGLE_LOADING = "ALERT_FORM_TOOGLE_LOADING";
/* <---- ALERT ----> */

/* TAG */
export const SET_TAG_LIST = "SET_TAG_LIST";
export const ADD_NEW_TAG = "ADD_NEW_TAG";
export const UPDATE_TAG = "UPDATE_TAG";
export const DELETE_TAG = "DELETE_TAG";
export const OPEN_TAG_FORM = "OPEN_TAG_FORM";
export const CLOSE_TAG_FORM = "CLOSE_TAG_FORM";
export const EDIT_SELECTED_TAG = "EDIT_SELECTED_TAG";
export const TAG_FORM_TOOGLE_LOADING = "TAG_FORM_TOOGLE_LOADING";
/* <---- TAG ----> */

/* TRAVEL */
export const SET_TRAVEL_LIST = "SET_TRAVEL_LIST";
export const ADD_NEW_TRAVEL = "ADD_NEW_TRAVEL";
export const UPDATE_TRAVEL = "UPDATE_TRAVEL";
export const DELETE_TRAVEL = "DELETE_TRAVEL";
export const OPEN_TRAVEL_FORM = "OPEN_TRAVEL_FORM";
export const CLOSE_TRAVEL_FORM = "CLOSE_TRAVEL_FORM";
export const EDIT_SELECTED_TRAVEL = "EDIT_SELECTED_TRAVEL";
export const TRAVEL_FORM_TOOGLE_LOADING = "TRAVEL_FORM_TOOGLE_LOADING";
/* <---- TRAVEL ----> */

/* BUDGET */
export const SET_BUDGET_LIST = "SET_BUDGET_LIST";
export const ADD_NEW_BUDGET = "ADD_NEW_BUDGET";
export const UPDATE_BUDGET = "UPDATE_BUDGET";
export const DELETE_BUDGET = "DELETE_BUDGET";
export const OPEN_BUDGET_FORM = "OPEN_BUDGET_FORM";
export const CLOSE_BUDGET_FORM = "CLOSE_BUDGET_FORM";
export const EDIT_SELECTED_BUDGET = "EDIT_SELECTED_BUDGET";
export const BUDGET_FORM_TOOGLE_LOADING = "BUDGET_FORM_TOOGLE_LOADING";
/* <---- BUDGET ----> */

/* COUNT */
export const SET_COUNT_LIST = "SET_COUNT_LIST";
export const ADD_NEW_COUNT = "ADD_NEW_COUNT";
export const UPDATE_COUNT = "UPDATE_COUNT";
export const DELETE_COUNT = "DELETE_COUNT";
export const OPEN_COUNT_FORM = "OPEN_COUNT_FORM";
export const CLOSE_COUNT_FORM = "CLOSE_COUNT_FORM";
export const EDIT_SELECTED_COUNT = "EDIT_SELECTED_COUNT";
export const COUNT_FORM_TOOGLE_LOADING = "COUNT_FORM_TOOGLE_LOADING";
/* <---- COUNT ----> */

/* EXPENSE */
export const SET_EXPENSE_LIST = "SET_EXPENSE_LIST";
export const ADD_NEW_EXPENSE = "ADD_NEW_EXPENSE";
export const UPDATE_EXPENSE = "UPDATE_EXPENSE";
export const DELETE_EXPENSE = "DELETE_EXPENSE";
export const OPEN_EXPENSE_FORM = "OPEN_EXPENSE_FORM";
export const CLOSE_EXPENSE_FORM = "CLOSE_EXPENSE_FORM";
export const EDIT_SELECTED_EXPENSE = "EDIT_SELECTED_EXPENSE";
export const EXPENSE_FORM_TOOGLE_LOADING = "EXPENSE_FORM_TOOGLE_LOADING";
/* <---- EXPENSE ----> */

/* GASS */
export const SET_GASS_LIST = "SET_GASS_LIST";
export const ADD_NEW_GASS = "ADD_NEW_GASS";
export const UPDATE_GASS = "UPDATE_GASS";
export const DELETE_GASS = "DELETE_GASS";
export const OPEN_GASS_FORM = "OPEN_GASS_FORM";
export const CLOSE_GASS_FORM = "CLOSE_GASS_FORM";
export const EDIT_SELECTED_GASS = "EDIT_SELECTED_GASS";
export const GASS_FORM_TOOGLE_LOADING = "GASS_FORM_TOOGLE_LOADING";
/* <---- GASS ----> */
