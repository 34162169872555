import { handleActions, handleAction } from "redux-actions";
import {
  AUTH,
  SET_PROFILE,
  SET_CURRENT_ROUTE,
  LOGOUT,
  SHOW_NOTIFICATION,
  CLOSE_NOTIFICATION,
  SET_SIDE_MENU_STATE,
  OPEN_ALERT_FORM,
  CLOSE_ALERT_FORM,
  EDIT_SELECTED_ALERT,
  ALERT_FORM_TOOGLE_LOADING,
  SET_ALERT_LIST,
  ADD_NEW_ALERT,
  UPDATE_ALERT,
  DELETE_ALERT,
  OPEN_TAG_FORM,
  CLOSE_TAG_FORM,
  EDIT_SELECTED_TAG,
  TAG_FORM_TOOGLE_LOADING,
  SET_TAG_LIST,
  ADD_NEW_TAG,
  UPDATE_TAG,
  DELETE_TAG,
  OPEN_TRAVEL_FORM,
  CLOSE_TRAVEL_FORM,
  EDIT_SELECTED_TRAVEL,
  TRAVEL_FORM_TOOGLE_LOADING,
  SET_TRAVEL_LIST,
  ADD_NEW_TRAVEL,
  UPDATE_TRAVEL,
  DELETE_TRAVEL,
  SET_EXPENSE_LIST,
  ADD_NEW_EXPENSE,
  UPDATE_EXPENSE,
  DELETE_EXPENSE,
  EXPENSE_FORM_TOOGLE_LOADING,
  CLOSE_EXPENSE_FORM,
  EDIT_SELECTED_EXPENSE,
  OPEN_EXPENSE_FORM,
  SET_COUNT_LIST,
  ADD_NEW_COUNT,
  UPDATE_COUNT,
  DELETE_COUNT,
  OPEN_COUNT_FORM,
  EDIT_SELECTED_COUNT,
  CLOSE_COUNT_FORM,
  COUNT_FORM_TOOGLE_LOADING,
  SET_BUDGET_LIST,
  ADD_NEW_BUDGET,
  UPDATE_BUDGET,
  DELETE_BUDGET,
  OPEN_BUDGET_FORM,
  CLOSE_BUDGET_FORM,
  EDIT_SELECTED_BUDGET,
  BUDGET_FORM_TOOGLE_LOADING,
  SET_GASS_LIST,
  ADD_NEW_GASS,
  UPDATE_GASS,
  DELETE_GASS,
  OPEN_GASS_FORM,
  EDIT_SELECTED_GASS,
  CLOSE_GASS_FORM,
  GASS_FORM_TOOGLE_LOADING,
  THEME,
} from "./../../constants";
import { createCrudReducers } from "../../utils/crudGenerator";

/* BASIC */
export const authorization = handleActions(
  {
    [AUTH]: (state, action) => action.payload,
    [LOGOUT]: () => ({ auth: false }),
  },
  null
);

/* PROFILE */
export const profile = handleActions(
  {
    [SET_PROFILE]: (state, action) => action.payload,
  },
  null
);

/* APP */
export const currentRoute = handleAction(
  SET_CURRENT_ROUTE,
  (state, action) => action.payload,
  {}
);

export const notifications = handleActions(
  {
    [SHOW_NOTIFICATION]: (state, action) => action.payload,
    [CLOSE_NOTIFICATION]: (state, action) => ({
      show: false,
      status: null,
      message: null,
    }),
  },
  { show: false, status: null, message: null }
);

/* APP */
export const app = handleActions(
  {
    [SET_SIDE_MENU_STATE]: (state, action) => {
      return { ...state, isCollapsedSideMenu: action.payload };
    },
  },
  { isCollapsedSideMenu: true }
);

export const currentTheme = handleAction(
  THEME,
  (state, action) => action.payload,
  false
);

export const alerts = handleActions(
  {
    [SET_ALERT_LIST]: (state, action) => action.payload,
    [ADD_NEW_ALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ALERT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const alertsForm = handleActions(
  {
    [OPEN_ALERT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ALERT_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_ALERT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ALERT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

const userReducers = createCrudReducers('USER');

export const users = userReducers.list;
export const usersForm = userReducers.form;


export const tags = handleActions(
  {
    [SET_TAG_LIST]: (state, action) => action.payload,
    [ADD_NEW_TAG]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_TAG]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_TAG]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const tagsForm = handleActions(
  {
    [OPEN_TAG_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_TAG_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_TAG]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [TAG_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const travels = handleActions(
  {
    [SET_TRAVEL_LIST]: (state, action) => action.payload,
    [ADD_NEW_TRAVEL]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_TRAVEL]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_TRAVEL]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const travelsForm = handleActions(
  {
    [OPEN_TRAVEL_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_TRAVEL_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_TRAVEL]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [TRAVEL_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const budgets = handleActions(
  {
    [SET_BUDGET_LIST]: (state, action) => action.payload,
    [ADD_NEW_BUDGET]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_BUDGET]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_BUDGET]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const budgetsForm = handleActions(
  {
    [OPEN_BUDGET_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_BUDGET_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_BUDGET]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [BUDGET_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const counts = handleActions(
  {
    [SET_COUNT_LIST]: (state, action) => action.payload,
    [ADD_NEW_COUNT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_COUNT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_COUNT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const countsForm = handleActions(
  {
    [OPEN_COUNT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_COUNT_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_COUNT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [COUNT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const expenses = handleActions(
  {
    [SET_EXPENSE_LIST]: (state, action) => action.payload,
    [ADD_NEW_EXPENSE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_EXPENSE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_EXPENSE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const expensesForm = handleActions(
  {
    [OPEN_EXPENSE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_EXPENSE_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_EXPENSE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [EXPENSE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const gass = handleActions(
  {
    [SET_GASS_LIST]: (state, action) => action.payload,
    [ADD_NEW_GASS]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_GASS]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_GASS]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const gassForm = handleActions(
  {
    [OPEN_GASS_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_GASS_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_GASS]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [GASS_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);
