import { AccountBookOutlined, CarOutlined, EnvironmentOutlined, EuroCircleOutlined, HomeOutlined, LoginOutlined, LogoutOutlined, PieChartOutlined, TagOutlined, UserOutlined, WalletOutlined } from '@ant-design/icons';
import React from 'react';
import { ROLES } from "./permissions";

export const routes = {
  home: {
    key: "home",
    slug: "home",
    to: "/home",
    title: "Dashboard",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },
  analytics: {
    key: "analytics",
    slug: "analytics",
    to: "/analytics",
    title: "Estadísticas",
    icon: <PieChartOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  profile: {
    key: "profile",
    slug: "profile",
    to: "/profile",
    title: "Perfil",
    icon: <UserOutlined />,
    showInMenu: false,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  tags: {
    key: "tags",
    slug: "tags",
    to: "/tags",
    title: "Tags",
    icon: <TagOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  travels: {
    key: "travels",
    slug: "travels",
    to: "/travels",
    title: "Viajes",
    icon: <EnvironmentOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  counts: {
    key: "counts",
    slug: "counts",
    to: "/counts",
    title: "Cuentas",
    icon: <AccountBookOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  expenses: {
    key: "expenses",
    slug: "expenses",
    to: "/expenses",
    title: "Gastos",
    icon: <EuroCircleOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  budgets: {
    key: "budgets",
    slug: "budgets",
    to: "/budgets",
    title: "Presupuestos",
    icon: <WalletOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  gass: {
    key: "gass",
    slug: "gass",
    to: "/gass",
    title: "Gasolina",
    icon: <CarOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  users: {
    key: "users",
    slug: "users",
    to: "/users",
    title: "Usuarios",
    icon: <UserOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  login: {
    key: "login",
    slug: "login",
    to: "/login",
    title: "LOGIN",
    icon: <LoginOutlined />,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  logout: {
    key: "logout",
    slug: "logout",
    to: "/logout",
    title: "Log out",
    icon: <LogoutOutlined />,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },
};
