import { useEffect } from "react";
import { notification } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getNotificationsState } from "../../store/selectors";
import { CLOSE_NOTIFICATION } from "../../constants";

const NotificationsHandler = () => {
	const state = useSelector(getNotificationsState, shallowEqual);
	const dispatch = useDispatch();

	// Usar el nuevo API de notificaciones
	const [api, contextHolder] = notification.useNotification();

	useEffect(() => {
		if (state && state.show && state.message) {
			console.log("state", state);
			const key = Date.now().toString();

			api.open({
				key,
				type: state.status || "info",
				placement: "topRight",
				message: state.message,
				duration: 2,
				closeIcon: null,
				onClose: () => dispatch({ type: CLOSE_NOTIFICATION }),
			});
		}
	}, [state, dispatch, api]);

	// Importante: devolver el contextHolder para que las notificaciones se rendericen
	return contextHolder;
};

export default NotificationsHandler;
